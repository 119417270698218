

/**umb_name:Heading 1*/
h1 {
	font-size:2.5rem;
	font-weight:bold;
}

/**umb_name:Heading 2*/
h2 {
	font-size: 1.5rem;
	font-weight: bold;
}

/**umb_name:Topic*/
.Topic {
	font-size: 1rem;
	font-weight: bold;
}
